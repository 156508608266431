import React, { Component, Fragment } from 'react'
import { X } from 'react-feather'

import './Popup.css'

class Popup extends Component {
  constructor(props) {
    super(props)
    this.state = { showPopup: false }
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    })
  }

  render() {
    const { children, popupContent = <div /> } = this.props
    return (
      <Fragment>
        <div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={this.togglePopup.bind(this)}
          >
            {children}
          </div>
        </div>

        {this.state.showPopup ? (
          <div className="Popup-Overlay">
            <div
              className="Popup-Background"
              onClick={this.togglePopup.bind(this)}
            ></div>
            <div
              className="Popup-Inner"
              style={{ width: '100%', maxHeight: '100%', height: 'auto' }}
            >
              <X
                style={{ cursor: 'pointer' }}
                class="Popup-Close"
                onClick={this.togglePopup.bind(this)}
              />
              {popupContent}
            </div>
          </div>
        ) : null}
      </Fragment>
    )
  }
}
export default Popup
