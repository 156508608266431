import React, { useState } from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Layout from '../components/Layout.js'
import Popup from '../components/Popup'
import MyContext from '../components/Context'

// Export Template for use in CMS previeww
export const ComponentsPageTemplate = ({
  title,
  title_ar,
  subtitle,
  subtitle_ar,
  featuredImage,
  books_ar,
  books_en,
  posts
}) => {
  const [flag, setFlag] = useState('book')
  return (
    <MyContext.Consumer>
      {cons => {
        if (!cons) {
          var cons = {
            strings: e => e,
            i18n: {
              language: 'ar'
            }
          }
        }
        return (
          <main>
            <section>
              <div style={{ marginBottom: '20px' }}>
                <PageHeader
                  title={cons.i18n.language == 'en' ? title : title_ar}
                  subtitle={cons.i18n.language == 'en' ? subtitle : subtitle_ar}
                  backgroundImage={featuredImage}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-around',
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              >
                {['book', 'report', 'video'].map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        cursor: 'pointer',
                        color: item == flag ? '#41942f' : 'black'
                      }}
                      onClick={() => setFlag(item)}
                    >
                      <p style={{ fontWeight: '700', fontSize: '17.5px' }}>
                        {cons.strings(item)}
                      </p>
                    </div>
                  )
                })}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginTop: '30px',
                  justifyContent: 'center'
                }}
              >
                {(cons.i18n.language == 'en' && flag == 'report'
                  ? books_en
                  : books_ar
                ).map((item, index) => {
                  return (flag == 'book' && item.libtype == 'book') ||
                    (flag == 'report' && item.libtype == 'report') ? (
                    <div
                      key={index}
                      style={{
                        marginTop: '60px',
                        width: '300px'
                      }}
                    >
                      <Popup
                        key={index}
                        popupContent={
                          <embed
                            src={item.pdf_file}
                            type="application/pdf"
                            width="100%"
                            height="500px"
                          />
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <img
                            alt=""
                            src={item.image}
                            style={{
                              width: '200px',
                              height: '250px'
                            }}
                          />
                          <div>
                            <p
                              className="book-title"
                              style={{
                                width: '200px',
                                textAlign: 'center'
                              }}
                            >
                              {item.title}
                            </p>
                          </div>
                        </div>
                      </Popup>
                    </div>
                  ) : flag == 'video' && item.libtype == 'video' ? (
                    <div
                      style={{
                        // background: 'red',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '90%',
                        marginBottom: '30px',
                        borderBottom: '1px solid #d8d7d7'
                      }}
                    >
                      <iframe
                        title={item.title}
                        src={
                          'https://www.youtube.com/embed/' +
                          item.youtube_file.split('v=')[1]
                        }
                        allow="autoplay; encrypted-media"
                        allowFullScreen="allowfullscreen"
                        width="600"
                        height="400"
                        frameBorder="0"
                        style={{
                          width: '100%',
                          maxWidth: '500px',
                          height: '250px',
                          marginBottom: '10px'
                        }}
                      ></iframe>
                      <div>
                        <p
                          style={{
                            textAlign: 'center'
                          }}
                        >
                          {item.title}
                        </p>
                      </div>
                    </div>
                  ) : null
                })}
              </div>
            </section>
          </main>
        )
      }}
    </MyContext.Consumer>
  )
}

const ComponentsPage = ({ data: { page, posts } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    // title={page.frontmatter.title || false}
  >
    <ComponentsPageTemplate
      {...page}
      {...page.frontmatter}
      body={page.html}
      books_ar={[].concat(page.frontmatter.books_ar).reverse()}
      books_en={[].concat(page.frontmatter.books_en).reverse()}
    />
  </Layout>
)

export default ComponentsPage

export const pageQuery = graphql`
  query ComponentsPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        title_ar
        template
        subtitle
        subtitle_ar
        featuredImage
        books_ar {
          pdf_file
          image
          alt
          title
          libtype
          youtube_file
        }
        books_en {
          pdf_file
          image
          alt
          title
          libtype
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            lang
            title
            date
            categories
            featuredImage
            excerpt
          }
        }
      }
    }
  }
`
